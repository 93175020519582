<template>
  <div class="container">
    <div class="row">
      <div class="col s12 grey-text text-darken-3">
        <p class = "text-center">
          <img
              src="@/assets/img/FAQ_Paradisi_Fiscali_Societa_Offshore_Conti_Bancari_Offshore.jpg"
              alt="FAQ"
              class="responsive-img"
          >
        </p>
        <div class="row">
          <div class="col s12">

            <h6>{{ $t("FAQ.Indice") }}</h6>
            <ul class="faq-list">
              <li>
                <a href="#faq-respuestaOne">{{ $t("FAQ.Cosa è un NFT?") }}</a>
              </li>
              <li>
                <a href="#faq-respuestaTwo">{{ $t("FAQ.Perchè registrare") }}</a>
              </li>
              <li>
                <a href="#faq-respuestaTree">{{ $t("FAQ.Quantocostailservizio?") }}</a>
              </li>
              <li>
                <a href="#faq-respuestaFour">{{ $t("FAQ.ComepagoilmioNFT?") }}</a>
              </li>
              <li>
                <a href="#faq-respuestaFive">{{ $t("FAQ.Su quale Blockcahin si Registra il mio NFT?") }}</a>
              </li>
              <li>
                <a href="#faq-respuestaSix"> {{ $t("FAQ.Perchè la Binance Smart Chain en non Ethereum?") }}</a>
              </li>
              <li>
                <a href="#faq-respuestaSeven"> {{ $t("FAQ.CheDevoFareperIniziare?") }}</a>
              </li>
              <li>
                <a href="#faq-respuestaEight">{{ $t("FAQ.PercheAutorizzareApplicazioneUSDT?") }}</a>
              </li>
              <li>
                <a href="#faq-respuestaNine">{{ $t("FAQ.QualisonoiVantaggidiRegistrareunMarchio?") }}</a>
              </li>
              <li>
                <a href="#faq-respuestaTen">{{ $t("FAQ.QualiDonoiVantaggidellaRegistrazioneInternazionaledelMarchio?") }}</a>
              </li>
              <li>
                <a href="#faq-respuestaEleven">{{ $t("FAQ.ComeFunzionalaRegistrazioneInternazionalediunMarchionelNodoConvenzionale?") }}</a>
              </li>
              <li>
                <a href="#faq-respuestaTwelve">{{ $t("FAQ.QualipaesifannopartedelSistemadiMadrid?") }}</a>
              </li>
              <li>
                <a href="#faq-respuestaThirteen">{{ $t("FAQ.CosaDevoFarePrimadiRegistrareunMarchio?") }}</a>
              </li>
              <li>
                <a href="#faq-respuestaFourteen">{{ $t("FAQ.QuantoDuraLinteroProcessodiRegistrazioneInternazionaleConvenzionale?") }}</a>
              </li>
              <li>
                <a href="#faq-respuestaFifteen">{{ $t("FAQ.CMoltaDifferenzadiPrezzoPerRegistrareunMarchiodaunPaeseAllaltro?") }}</a>
              </li>
              <li>
                <a href="#faq-respuestaSixteen">{{ $t("FAQ.PerEsempiocomeFaccioaRegistrareunMarchioinMessico?") }}</a>
              </li>
              <li>
                <a href="#faq-respuestaSeventeen">{{ $t("FAQ.EComesiRegistraunMarchioinSpagna?") }}</a>
              </li>
              <li>
                <a href="#faq-respuestaEighteen">{{ $t("FAQ.NegliStatiUnitiCapiscoCheMoltoDiversoComesiRegistraunMarchio?") }}</a>
              </li>
              <li>
                <a href="#faq-respuestaNighteen">{{ $t("FAQ.ComesiRegistraunMarchioPanama?") }}</a>
              </li>
              <li>
                <a href="#faq-respuestaTwenty">{{ $t("FAQ.EComesiRegistraunMarchioinColombia?") }}</a>
              </li>
              <li>
                <a href="#faq-respuestaTwentyOne">{{ $t("FAQ.HoLettoQuestiEsempiQuindiaLivelloLegaleQualeProteggedipiuilMioMarchio?") }}</a>
              </li>
            </ul>
          </div>
        </div>
        <h6 class ="blue-grey-text text-darken-4" id="faq-respuestaOne">
          {{ $t("FAQ.Cosa è un NFT?") }}
        </h6>
        <p v-html="$t('FAQ.CosaunNFText')"></p>

        <h6 class = "blue-grey-text text-darken-4" id="faq-respuestaTwo">
          {{ $t("FAQ.Perchè registrare") }}
        </h6>
        <p v-html="$t('FAQ.PerchEregistrareText')" ></p>

        <h6 class = "blue-grey-text text-darken-4" id="faq-respuestaTree">
          {{ $t("FAQ.Quantocostailservizio?") }}
        </h6>
        <p v-html="$t('FAQ.QuantocostailservizioText')"></p>

        <h6 class = "blue-grey-text text-darken-4" id="faq-respuestaFour">
          {{ $t("FAQ.ComepagoilmioNFT?") }}
        </h6>
        <p v-html="$t('FAQ.ComepagoilmioNFTText')"></p>

        <h6 class = "blue-grey-text text-darken-4" id="faq-respuestaFive">
          {{ $t("FAQ.Su quale Blockcahin si Registra il mio NFT?") }}
        </h6>
        <p v-html="$t('FAQ.FAQSuqualeBlockcahinNFTText')">

        </p>

        <h6 class = "blue-grey-text text-darken-4" id="faq-respuestaSix">
          {{ $t("FAQ.Perchè la Binance Smart Chain en non Ethereum?") }}
        </h6>
        <p v-html="$t('FAQ.PercheBinanceSmartChainText')"></p>

        <h6 class = "blue-grey-text text-darken-4" id="faq-respuestaSeven">
          {{ $t("FAQ.CheDevoFareperIniziare?") }}
        </h6>
        <p v-html="$t('FAQ.CheDevoFareperIniziareText')"></p>

        <h6 class = "blue-grey-text text-darken-4" id="faq-respuestaEight">
          {{ $t("FAQ.PercheAutorizzareApplicazioneUSDT?") }}
        </h6>
        <p v-html="$t('FAQ.PercheAutorizzareApplicazioneUSDTText', {
          'action': `<a href='https://metamask.io/download' target='_blank'>${$t('FAQ.PercheAutorizzareApplicazioneUSDURL')}</a>`,
        })"></p>
        <h6 class = "blue-grey-text text-darken-4" id="faq-respuestaNine">
          {{ $t("FAQ.QualisonoiVantaggidiRegistrareunMarchio?") }}
        </h6>
        <p v-html="$t('FAQ.QualisonoiVantaggidiRegistrareunMarchioText')"></p>
        <h6 class = "blue-grey-text text-darken-4" id="faq-respuestaTen">
          {{ $t("FAQ.QualiDonoiVantaggidellaRegistrazioneInternazionaledelMarchio?") }}
        </h6>
        <p v-html="$t('FAQ.QualiDonoiVantaggidellaRegistrazioneInternazionaledelMarchioText')"></p>
        <h6 class = "blue-grey-text text-darken-4" id="faq-respuestaEleven">
          {{ $t("FAQ.ComeFunzionalaRegistrazioneInternazionalediunMarchionelNodoConvenzionale?") }}
        </h6>
        <p v-html="$t('FAQ.ComeFunzionalaRegistrazioneInternazionalediunMarchionelNodoConvenzionaleText')"></p>
        <h6 class = "blue-grey-text text-darken-4" id="faq-respuestaTwelve">
          {{ $t("FAQ.QualipaesifannopartedelSistemadiMadrid?") }}
        </h6>
        <p v-html="$t('FAQ.QualipaesifannopartedelSistemadiMadridText')"></p>

        <h6 class = "blue-grey-text text-darken-4" id="faq-respuestaThirteen">
          {{ $t("FAQ.CosaDevoFarePrimadiRegistrareunMarchio?") }}
        </h6>
        <p v-html="$t('FAQ.CosaDevoFarePrimadiRegistrareunMarchioText', {
          'action': `<a href='https://www.wipo.int/reference/es/branddb/' target='_blank'>${$t('FAQ.CosaDevoFarePrimadiRegistrareunMarchioTextURL')}</a>`,
          'action2': `<a href='https://madrid.wipo.int/feecalcapp/' target='_blank'>${$t('FAQ.CosaDevoFarePrimadiRegistrareunMarchioTextURLTwo')}</a>`,
        })"></p>
        <h6 class = "blue-grey-text text-darken-4" id="faq-respuestaFourteen">
          {{ $t("FAQ.QuantoDuraLinteroProcessodiRegistrazioneInternazionaleConvenzionale?") }}
        </h6>
        <p v-html="$t('FAQ.QuantoDuraLinteroProcessodiRegistrazioneInternazionaleConvenzionaleText')"></p>
        <h6 class = "blue-grey-text text-darken-4" id="faq-respuestaFifteen">
          {{ $t("FAQ.CMoltaDifferenzadiPrezzoPerRegistrareunMarchiodaunPaeseAllaltro?") }}
        </h6>
        <p v-html="$t('FAQ.CMoltaDifferenzadiPrezzoPerRegistrareunMarchiodaunPaeseAllaltroText')"></p>
        <h6 class = "blue-grey-text text-darken-4" id="faq-respuestaSixteen">
          {{ $t("FAQ.PerEsempiocomeFaccioaRegistrareunMarchioinMessico?") }}
        </h6>
        <p v-html="$t('FAQ.PerEsempiocomeFaccioaRegistrareunMarchioinMessicoText', {
          'action': `<a href='https://www.gob.mx/impi' target='_blank'>${$t('FAQ.PerEsempiocomeFaccioaRegistrareunMarchioinMessicoTextURL')}</a>`,
        })"></p>
        <h6 class = "blue-grey-text text-darken-4" id="faq-respuestaSeventeen">
          {{ $t("FAQ.EComesiRegistraunMarchioinSpagna?") }}
        </h6>
        <p v-html="$t('FAQ.EComesiRegistraunMarchioinSpagnaText', {
          'action': `<a href='https://www.oepm.es/' target='_blank'>${$t('FAQ.EComesiRegistraunMarchioinSpagnaTextURL')}</a>`,
        })"></p>
        <h6 class = "blue-grey-text text-darken-4" id="faq-respuestaEighteen">
          {{ $t("FAQ.NegliStatiUnitiCapiscoCheMoltoDiversoComesiRegistraunMarchio?") }}
        </h6>
        <p v-html="$t('FAQ.NegliStatiUnitiCapiscoCheMoltoDiversoComesiRegistraunMarchioText', {
          'action': `<a href='https://www.uspto.gov/' target='_blank'>${$t('FAQ.NegliStatiUnitiCapiscoCheMoltoDiversoComesiRegistraunMarchioTextURL')}</a>`,
        })"></p>
        <h6 class = "blue-grey-text text-darken-4" id="faq-respuestaNighteen">
          {{ $t("FAQ.ComesiRegistraunMarchioPanama?") }}
        </h6>
        <p v-html="$t('FAQ.ComesiRegistraunMarchioPanamaText', {
          'action': `<a href='https://www.uspto.gov/' target='_blank'>${$t('FAQ.ComesiRegistraunMarchioPanamaTextURL')}</a>`,
        })"></p>
        <h6 class = "blue-grey-text text-darken-4" id="faq-respuestaTwenty">
          {{ $t("FAQ.EComesiRegistraunMarchioinColombia?") }}
        </h6>
        <p v-html="$t('FAQ.EComesiRegistraunMarchioinColombiaText', {
          'action': `<a href='https://www.sic.gov.co/' target='_blank'>${$t('FAQ.EComesiRegistraunMarchioinColombiaTextURL')}</a>`,
          'action2': `<a href='https://www.wipo.int/classifications/nice/es/index.html' target='_blank'>${$t('FAQ.EComesiRegistraunMarchioinColombiaTextURLTwo')}</a>`,
        })"></p>
        <h6 class = "blue-grey-text text-darken-4" id="faq-respuestaTwentyOne">
          {{ $t("FAQ.HoLettoQuestiEsempiQuindiaLivelloLegaleQualeProteggedipiuilMioMarchio?") }}
        </h6>
        <p v-html="$t('FAQ.HoLettoQuestiEsempiQuindiaLivelloLegaleQualeProteggedipiuilMioMarchioText')"></p>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .s12{ padding-top: 20px !important;}
  h6{font-weight: bold;}
  .link{
    font-weight: bold;
    cursor: pointer;
  }
  .faq-list {

  }

  .blue-grey-text {
    font-weight: bold;
  }

  .faq-list li {
    padding: 0.3em 0;
  }

  .faq-list a {
    font-weight: bold;
    color: #091df5 !important;
  }

  .faq-list a:hover {
    text-decoration: none;
  }
</style>
